import { useState, useContext, useEffect } from "react";
import { Context } from '../stores/Provider';
import { PostMethod } from '../api/Api.js';
import { mailValidate, singlePasswordValidate } from '../validators/publicValidator'
import { useLocation, useNavigate } from "react-router-dom";
import { getSession, setAgentMasterInfoData, setBankAccountInfoData, setEconomicConditionMasterInfoData, setEconomicDevidedInfoData, setEconomicInfoData, setFileInfoData, setLoginInfoData, setMerchantInfoData, setOperationInfoData, setRepresentativeInfoData, setReviewContentMasterInfoData, setSession, setShopInfoData, setSystemSettingMasterInfoData, setUserInfoData } from "../sessionStorage/sessionStorageMethod";
import { paymentSiteChangeToPhraseHandler } from "../function/paymentSiteToCode";
import { setIsLoading } from "../function/setIsLoading";
import { setSessionAllData } from "../function/setInitSessionData";
import { ToolChipDiv } from "../constance/constance.js";

const useLoginHooks = () => {
    const { state, dispatch } = useContext(Context);
    const [email, setEmail] = useState(state.login.email);
    const [password, setPassword] = useState(state.login.password);
    const [isClosed, setIsClosed] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()
    
    const [errMsgs, setErrMsg] = useState({
        email: '',
        password: '',
    })

    // 変数返却
    const EmailData = email
    const PasswordData = password
    const ErrMsgs = errMsgs
    let loginData = {email:"", password:""}
    //セッションデータ抽出
	const userInfoData = getSession('user')
    const loginInfoData = getSession('login')
	const agentMasterInfoData = getSession('agent_master')
	const merchantInfoData = getSession('merchant')
	const bankAccountInfoData = getSession('bank_account')
	const representativeInfoData = getSession('representative')
	const operationInfoData = getSession('operation')
	const economicInfoData = getSession('economic_condition')
	const fileInfoData = getSession('files')
	const shopInfoData = getSession('shop')
    const economicConditionMasterInfoData = getSession('economic_condition_master')
    const systemSettingMasterInfoData = getSession('system_setting_master')
    const reviewContentMasterInfoData = getSession('review_contents_master')

    // ブラウザバックを禁止する
    const browserBackBan = () =>{
        window.history.pushState(null, null, null);
        window.addEventListener('popstate', () => {
            window.history.pushState(null, null, null)
            return
        });
    }

    //ログイン画面読み込み時処理
    useEffect(()=>{
        if(loginInfoData !== null && loginInfoData.code === 200) return
        setSessionAllData()
        //認証ページから遷移の場合のみブラウザバック禁止
        if(location.state === null || location.state.routeTo !== 'login' ) return
        browserBackBan()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    // 経済状況マスタ情報の成型ハンドラ
    const economicConditionMasterInsertHandler = (loginResponse) => {
        const economicConditionArray = ['credit', 'cvs', 'bank', 'atobarai']
        let economicConditionMasterData = {};
        economicConditionArray.forEach((key)=>{
            for(let resKey in loginResponse.response_data.masters){
                if(resKey.split('_')[0] === key){
                    let preEconomicConditionMasterData = {...economicConditionMasterInfoData[key]}
                    for(let item in loginResponse.response_data.masters[resKey]){
                        let value = loginResponse.response_data.masters[resKey][item]
                        if(item === "payment_site"){
                            value = paymentSiteChangeToPhraseHandler(value)
                        }
                        preEconomicConditionMasterData = {...preEconomicConditionMasterData, [item]:value}
                    }
                    economicConditionMasterData = {...economicConditionMasterData, [key]:preEconomicConditionMasterData}
                }
            }
        })

        return economicConditionMasterData
    }

    //ショップ情報に固定値を挿入するハンドラ
    const reviewContentInsertHandler = (loginResponse,reviewContentInsert) => {

        let reviewContentMasterPlusShopAddition;
        let masterKeyArray = [];
        for(let key in loginResponse.response_data.masters.shop_addition_masters){
            let keyName = 'shop_addition_' + key
            masterKeyArray.push(keyName)
            reviewContentMasterPlusShopAddition = {...reviewContentMasterPlusShopAddition, [keyName]: loginResponse.response_data.masters.shop_addition_masters[key]}
        }
        for(let shopKey in reviewContentInsert){
            if(masterKeyArray.indexOf(shopKey) === -1){
                reviewContentMasterPlusShopAddition = {...reviewContentMasterPlusShopAddition, [shopKey]:reviewContentInsert[shopKey]}
            }
        }
        return reviewContentMasterPlusShopAddition
    }

    //オプション料金名(system_usage_fee,monthly_option_fee)をセット
    const setOptionName = (option) => {
        let monthlyName = ""
        let systemName = ""
        switch(option){
            case "option_fee_3d_secure":
                monthlyName = "monthly_option_fee_3d_secure"
                systemName = "system_usage_fee_3d_secure"
                break
            case "option_fee_cvv":
                monthlyName = "monthly_option_fee_cvv"
                systemName = "system_usage_fee_cvv"
                break
            case "option_fee_confirmation_email":
                monthlyName = "monthly_option_fee_confirmation_email"
                systemName = "system_usage_fee_confirmation_email"
                break
            case "option_fee_continuous_billing":
                monthlyName = "monthly_option_fee_continuous_billing"
                systemName = "system_usage_fee_continuous_billing"
                break
            case "option_fee_payment_now":
                monthlyName = "monthly_option_fee_payment_now"
                systemName = "system_usage_fee_payment_now"
                break
            case "option_fee_customer_support":
                monthlyName = "monthly_option_fee_customer_support"
                systemName = "system_usage_fee_customer_support"
                break
            case "option_fee_div2_bonus":
                monthlyName = "monthly_option_fee_div2_bonus"
                systemName = "system_usage_fee_div2_bonus"
                break
            case "option_fee_id_password_storage":
                monthlyName = "monthly_option_fee_id_password_storage"
                systemName = "system_usage_fee_id_password_storage"
                break
            default:
                break
        }
        return {monthlyName, systemName}
    }
    //EntryAPIのポスト用経済条件データにセット
    const economicInsertHandler = (loginResponse) => {
        let economicData = {...economicInfoData}
        //マスターデータを経済条件にセット,マスターデータ以外のものに前回のデータをセット
        const masterKeyArray = ["credit", "cvs", "bank", "atobarai"]
        masterKeyArray.forEach((masterKey)=>{
            for(let key in loginResponse.response_data.masters){
                if(key.split('_')[0] === masterKey){
                    let preEconomicData = {...economicInfoData[masterKey]};
                    let economicRequirementData = {...economicInfoData[masterKey].economic_requirement};
                    let systemSettingData = {...economicInfoData[masterKey].system_setting, select_flag:masterKey === 'credit' ? true : false}
                    //マスタデータ挿入（一度全データ挿入）
                    for(let secoundLoginKey in loginResponse.response_data.masters[key]){
                        //option_fee_early_deposit_service_twoとoption_fee_early_deposit_service_three以外のoption_fee_を申し込み情報にセット
                        if(secoundLoginKey === 'option_fee_early_deposit_service_three' || secoundLoginKey === 'option_fee_early_deposit_service_two') continue
                        if(secoundLoginKey.indexOf('option_fee_') !== -1){
                            const {optionName, systemName} = setOptionName(secoundLoginKey)
                            economicRequirementData = {...economicRequirementData,[optionName]:loginResponse.response_data.masters[key][secoundLoginKey],[systemName]:loginResponse.response_data.masters[key][secoundLoginKey]}
                        }else{
                            let value = loginResponse.response_data.masters[key][secoundLoginKey]
                            economicRequirementData = {...economicRequirementData,[secoundLoginKey]:value}
                        }
                    }
                    //前回値データ挿入(配列内の要素とoptionFeeのみ挿入)
                    const requireKey = ["payment_site","early_payment_flag","monthly_payment_method","zeus_incentive_id"]
                    if(loginResponse.response_data.services){
                        if(loginResponse.response_data.services[masterKey]){
                            for(let secoundEntryKey in loginResponse.response_data.services[masterKey].economic_requirement){
                                if(secoundEntryKey.indexOf('monthly_option_') !== -1|| secoundEntryKey.indexOf('system_usage_fee_')  !== -1 || requireKey.indexOf(secoundEntryKey) !== -1){
                                    let value = loginResponse.response_data.services[masterKey].economic_requirement[secoundEntryKey]
                                    economicRequirementData = {...economicRequirementData,[secoundEntryKey]:value}
                                }
                                if((secoundEntryKey.indexOf('monthly_option_') !== -1 && agentMasterInfoData.demand_code === 'system') || (secoundEntryKey.indexOf('system_usage_fee_') !== -1 && agentMasterInfoData.demand_code === 'option')){
                                    economicRequirementData = {...economicRequirementData,[secoundEntryKey]:null}
                                }
                            }
                            //system_setting挿入
                            for(let systemSettingKey in loginResponse.response_data.services[masterKey].system_setting){
                                systemSettingData = {...systemSettingData, [systemSettingKey]:loginResponse.response_data.services[masterKey].system_setting[systemSettingKey]}
                            }
                        }
                    }
                    //srvice_code_listからフラグを生成
                    if(loginResponse.response_data.service_code_list){
                        loginResponse.response_data.service_code_list.split('/').forEach((serviceKey)=>{
                            if(serviceKey === masterKey){
                                systemSettingData = {...systemSettingData, select_flag:true}
                            }
                        })
                    }else{
                        // あと払いキャンペーン対策
                        // srvice_code_listがまだ登録されていない状態の時、初期値を選択状態にする
                        const arrayInitSelectFlag = ["bank", "atobarai"]
                        if(arrayInitSelectFlag.includes(masterKey)){
                            systemSettingData = {...systemSettingData, select_flag:true}
                        }
                    }
                    //支払いサイト変換
                    economicRequirementData.payment_site = paymentSiteChangeToPhraseHandler(economicRequirementData.payment_site)
                    preEconomicData = {...preEconomicData, economic_requirement:economicRequirementData, system_setting:systemSettingData}
                    economicData = {...economicData, [masterKey]:preEconomicData}
                }
            }
        })
        //service_code_listの反映
        economicData = {...economicData, service_code_list:loginResponse.response_data.service_code_list}

        return economicData
    }

    //ショップ状態をラジオボタンの形に成型
    const siteStatusChangeToRadio = (siteStatus) => {
        let siteStatusObj = {site_status_code:"",site_status_method:""}
        if(siteStatus === "0"){
            siteStatusObj = {site_status_code:0,site_status_method:0}
        }else if(siteStatus === "1"){
            siteStatusObj = {...siteStatusObj,site_status_code:1}
        }else if(siteStatus === "2"){
            siteStatusObj = {site_status_code:0,site_status_method:1}
        }
        return siteStatusObj
    }
    //nullの数を調べる
    // const nullChecker = (key) => {
    //     let keyCount = Object.keys(key)
    //     let nullCountMerchant = 0
    //     for(let value in key){
    //         value === null && nullCountMerchant++
    //     }
    //     return !nullCountMerchant === keyCount
    // }

    //商品コンテンツをフラグ化
    const productContentCodeToFlag = (contentCode) => {
        let flags = {sell_goods:false, service:false, digital_email:false}
        if(contentCode.indexOf('SELL_GOODS') !== -1) flags = {...flags, sell_goods:true}
        if(contentCode.indexOf('SERVICE') !== -1) flags = {...flags, service:true}
        if(contentCode.indexOf('DIGITAL_EMAIL') !== -1) flags = {...flags, digital_email:true}
        
        return flags
    }
    

    //エントリーデータ成型用ハンドラ
    const entryDataSuitHandler = (loginResponse) => {
		//店舗_郵便番号
        let merchant_register_post_code = ""
        let merchant_register_post_code3 = "";
        let merchant_register_post_code4 = "";
        //店舗電話番号
        let merchant_phone_number_before = ""
        let merchant_phone_number_middle = ""
        let merchant_phone_number_after = ""
        //代表者_誕生日
        let representative_birthday_CE = "";
        let representative_birthday_month = "";
        let representative_birthday_day = "";
        let operator_phone_number_before = ""
        let operator_phone_number_middle = ""
        let operator_phone_number_after = ""
        let support_operator_email = ""
        let support_operator_phone_number = ""
        let support_operator_phone_number_before = ""
        let support_operator_phone_number_middle = ""
        let support_operator_phone_number_after = ""
        let shop_addition_authentication_imple_flag = ''
        let shop_addition_security_code_imple_flag = ''
        // let shop_addition_use_continuation = false
        let merchantObj = {};
        let representativeObj = {};
        let operatorObj = {};
        let bankObj = {};
        let siteStatusObj = {};
        let productCodeFlags = {}
        // let fileObj = {}
        const responseMerchant = loginResponse.response_data.entry_info_merchant
        //GetしたデータをRecoil用に成型
		const objDevide = (value) => {
			let devidedObj = {}
			for(let key in responseMerchant){
				if(key.split('_')[0] === value){
                    let keyValue = responseMerchant[key] ? responseMerchant[key] : ""
                    if(key === "merchant_annual_business" && responseMerchant[key] === 0 ) keyValue = 0
					devidedObj = {
						...devidedObj, [key] : keyValue
					}
				}
			}
			return devidedObj
		}

        if(responseMerchant){
            const merchantPhoneArray = responseMerchant.merchant_phone_number.split('-')
            const operatorPhoneArray = responseMerchant.operator_phone_number.split('-')
            const supportOperatorPhoneArray = responseMerchant.support_operator_phone_number.split('-')
            merchant_register_post_code = responseMerchant.merchant_register_post_code.replace('-','')
            merchant_register_post_code3 = merchant_register_post_code.substring(0, 3);
            merchant_register_post_code4 = merchant_register_post_code.substring(3, 7);
            merchant_phone_number_before = merchantPhoneArray[0]   
            merchant_phone_number_middle = merchantPhoneArray[1]
            merchant_phone_number_after = merchantPhoneArray[2]
            if(responseMerchant.representative_birthday !== "0000-00-00" && responseMerchant.representative_birthday !== ""){
                representative_birthday_CE = responseMerchant.representative_birthday.split('-')[0]
                //誕生月は一桁の場合、十の位を排除
                representative_birthday_month = (responseMerchant.representative_birthday.split('-')[1].substr(0,1) === '0' ? responseMerchant.representative_birthday.split('-')[1].substr(1,2) : responseMerchant.representative_birthday.split('-')[1])
                representative_birthday_day = (responseMerchant.representative_birthday.split('-')[2].split(' ')[0].substr(0,1) === '0' ? responseMerchant.representative_birthday.split('-')[2].split(' ')[0].substr(1,2) : responseMerchant.representative_birthday.split('-')[2].split(' ')[0])
            }
            
            support_operator_email = responseMerchant.support_operator_email
            operator_phone_number_before = operatorPhoneArray[0]
            operator_phone_number_middle = operatorPhoneArray[1]
            operator_phone_number_after = operatorPhoneArray[2]
            support_operator_phone_number = responseMerchant.support_operator_phone_number
            support_operator_phone_number_before = supportOperatorPhoneArray[0]
            support_operator_phone_number_middle = supportOperatorPhoneArray[1]
            support_operator_phone_number_after = supportOperatorPhoneArray[2]
            merchantObj = objDevide('merchant')
            representativeObj = objDevide('representative')
            operatorObj = objDevide('operator')
            bankObj = loginResponse.response_data.entry_info_bank
            bankObj = {
                ...bankObj, 
                bank_account_category:bankObj.bank_account_category.toString(),
            }
        }
        if(loginResponse.response_data.entry_info_shop){
            siteStatusObj = siteStatusChangeToRadio(loginResponse.response_data.entry_info_shop.shop_addition_site_status)
            productCodeFlags = productContentCodeToFlag(loginResponse.response_data.entry_info_shop.shop_addition_product_content_code)
        }

        if(loginResponse.response_data.services && loginResponse.response_data.services.credit){
            shop_addition_authentication_imple_flag = loginResponse.response_data.services.credit.system_setting.threed_secure ? "1" : "3"
            shop_addition_security_code_imple_flag = loginResponse.response_data.services.credit.system_setting.cvv ? "1" : "3"
            // shop_addition_use_continuation = loginResponse.response_data.services.credit.system_setting.subscription
        }
	
		//ファイルのtypeキー作成
		// for(let fileTitle in loginResponse.response_data.entry_info_file){
        //     let fileArray = []
		// 	loginResponse.response_data.entry_info_file[fileTitle].forEach((file,index)=>{
        //         let unitFileObj = loginResponse.response_data.entry_info_file[fileTitle][index]
		// 		let extendedChild = file.file_name.split('.')[1]
        //         let file_type
        //         let file_data
        //         if(extendedChild === "pdf" || extendedChild === "PDF"){
        //             file_type = "application/pdf"
        //         } else {
        //             file_type = "image/"+ extendedChild
        //         }
        //         file_data = 'data:' + file_type + ';base64,' + loginResponse.response_data.entry_info_file[fileTitle][index].file_data
        //         unitFileObj = {...unitFileObj, file_data:file_data, type:file_type}
        //         fileArray.push(unitFileObj)
		// 	})
        //     fileObj = {...fileObj, [fileTitle]:fileArray}
		// }
        
		const merchantData = {
			...merchantObj,
			merchant_register_post_code3: merchant_register_post_code3,
			merchant_register_post_code4: merchant_register_post_code4,
            merchant_phone_number_before: merchant_phone_number_before,
            merchant_phone_number_middle: merchant_phone_number_middle,
            merchant_phone_number_after: merchant_phone_number_after,
		}
		const representativeData = {
			...representativeObj,
			representative_birthday_CE: representative_birthday_CE,
			representative_birthday_month: representative_birthday_month,
			representative_birthday_day: representative_birthday_day,
		}
		const operatorData = {
			...operatorObj,
            operator_phone_number_before:operator_phone_number_before,
            operator_phone_number_middle:operator_phone_number_middle,
            operator_phone_number_after:operator_phone_number_after,
			support_operator_email: support_operator_email,
			support_operator_phone_number: support_operator_phone_number,
			support_operator_phone_number_before: support_operator_phone_number_before,
			support_operator_phone_number_middle: support_operator_phone_number_middle,
			support_operator_phone_number_after: support_operator_phone_number_after,
		}
		const reviewContentInsert = {
			...loginResponse.response_data.entry_info_shop,
            shop_addition_product_content_code_digital_email:productCodeFlags.digital_email,
            shop_addition_product_content_code_sell_goods:productCodeFlags.sell_goods,
            shop_addition_product_content_code_service:productCodeFlags.service,
            shop_addition_site_status_code:siteStatusObj.site_status_code,
            shop_addition_site_status_method:siteStatusObj.site_status_method,
			shop_addition_non_infringing_product_presence_absence_flag: false,
			shop_addition_available_product_flag: false,
            shop_addition_authentication_imple_flag: shop_addition_authentication_imple_flag,
            shop_addition_security_code_imple_flag: shop_addition_security_code_imple_flag,
            // shop_addition_use_continuation: shop_addition_use_continuation,

		}
        
        return {merchantData,representativeData,operatorData,bankObj,reviewContentInsert}
    }
    //システム設定マスタによって経済条件の値をヌルに変更,type補完,支払いサイト（クレジット）フラグ補完
    const setEconomicFlagBySystemSetting = (systemSettingData,economicData) => {
        let economicInsert = {...economicData}
        let creditEconomicInsert = {...economicData.credit.economic_requirement}
        let bankEconomicInsert = {...economicData.bank.economic_requirement}
        let cvsEconomicInsert = {...economicData.cvs.economic_requirement}
        let atobaraiEconomicInsert = {...economicData.atobarai.economic_requirement}
        let creditSystemInsert = {...economicData.credit.system_setting}
        let bankSystemInsert = {...economicData.bank.system_setting}
        let cvsSystemInsert = {...economicData.cvs.system_setting}
        let atobaraiSystemInsert = {...economicData.atobarai.system_setting}
        if(economicData.credit.economic_requirement.payment_site === '月2回締め 月2回払い' || economicData.credit.economic_requirement.payment_site === '月3回締め 月3回払い'){
            creditEconomicInsert.early_payment_flag = true
        }
        if(!systemSettingData.credit_3d_agent_flag){
            creditSystemInsert.threed_secure = false
        }
        if(systemSettingData.credit_3d_agent_flag && !systemSettingData.credit_3d_merchant_flag){
            creditSystemInsert.threed_secure = true
        }
        if(!systemSettingData.credit_auth_agent_flag){
            creditSystemInsert.auth = false
        }
        if(systemSettingData.credit_auth_agent_flag && !systemSettingData.credit_auth_merchant_flag){
            creditSystemInsert.auth = true
        }
        if(!systemSettingData.credit_cgi_agent_flag){
            creditSystemInsert.cgi = false
        }
        if(systemSettingData.credit_cgi_agent_flag && !systemSettingData.credit_cgi_merchant_flag){
            creditSystemInsert.cgi = true
        }
        if(!systemSettingData.credit_customer_support_agent_flag){
            creditSystemInsert.customer_support = false
        }
        if(systemSettingData.credit_customer_support_agent_flag && !systemSettingData.credit_customer_support_merchant_flag){
            creditSystemInsert.customer_support = true
        }
        if(!systemSettingData.credit_cvv_agent_flag){
            creditSystemInsert.cvv = false
        }
        if(systemSettingData.credit_cvv_agent_flag && !systemSettingData.credit_cvv_merchant_flag){
            creditSystemInsert.cvv = true
        }
        if(!systemSettingData.credit_div2_bonus_agent_flag){
            creditSystemInsert.div = false
        }
        if(systemSettingData.credit_div2_bonus_agent_flag && !systemSettingData.credit_div2_bonus_merchant_flag){
            creditSystemInsert.div = true
        }
        if(!systemSettingData.credit_id_password_storage_agent_flag){
            creditSystemInsert.id_password_storage = false
        }
        if(systemSettingData.credit_id_password_storage_agent_flag && !systemSettingData.credit_id_password_storage_merchant_flag){
            creditSystemInsert.id_password_storage = true
        }
        if(!systemSettingData.credit_send_mail_agent_flag){
            creditSystemInsert.send_mail = false
        }
        if(systemSettingData.credit_send_mail_agent_flag && !systemSettingData.credit_send_mail_merchant_flag){
            creditSystemInsert.send_mail = true
        }
        if(!systemSettingData.credit_subscription_agent_flag){
            creditSystemInsert.subscription = false
        }
        if(systemSettingData.credit_subscription_agent_flag && !systemSettingData.credit_subscription_merchant_flag){
            creditSystemInsert.subscription = true
        }
        if(!systemSettingData.credit_payment_now_agent_flag){
            creditSystemInsert.payment_now = false
        }
        if(systemSettingData.credit_payment_now_agent_flag && !systemSettingData.credit_payment_now_merchant_flag){
            creditSystemInsert.payment_now = true
        }
        if(!systemSettingData.cvs_cgi_agent_flag){
            cvsSystemInsert.cgi = false
        }
        if(systemSettingData.cvs_cgi_agent_flag && !systemSettingData.cvs_cgi_merchant_flag){
            cvsSystemInsert.cgi = true
        }
        if(!systemSettingData.cvs_customer_support_agent_flag){
            cvsSystemInsert.customer_support = false
        }
        if(systemSettingData.cvs_customer_support_agent_flag && !systemSettingData.cvs_customer_support_merchant_flag){
            cvsSystemInsert.customer_support = true
        }
        if(!systemSettingData.cvs_send_mail_agent_flag){
            cvsSystemInsert.send_mail = false
        }
        if(systemSettingData.cvs_send_mail_agent_flag && !systemSettingData.cvs_send_mail_merchant_flag){
            cvsSystemInsert.send_mail = true
        }
        if(!systemSettingData.cvs_early_payment_service_agent_flag){
            cvsEconomicInsert.early_payment_flag = false
        }
        if(systemSettingData.cvs_early_payment_service_agent_flag && !systemSettingData.cvs_early_payment_service_merchant_flag){
            cvsEconomicInsert.early_payment_flag = true
        }
        if(!systemSettingData.bank_cgi_agent_flag){
            bankSystemInsert.cgi = false
        }
        if(systemSettingData.bank_cgi_agent_flag && !systemSettingData.bank_cgi_merchant_flag){
            bankSystemInsert.cgi = true
        }
        if(!systemSettingData.bank_customer_support_agent_flag){
            bankSystemInsert.customer_support = false
        }
        if(systemSettingData.bank_customer_support_agent_flag && !systemSettingData.bank_customer_support_merchant_flag){
            bankSystemInsert.customer_support = true
        }
        if(!systemSettingData.bank_send_mail_agent_flag){
            bankSystemInsert.send_mail = false
        }
        if(systemSettingData.bank_send_mail_agent_flag && !systemSettingData.bank_send_mail_merchant_flag){
            bankSystemInsert.send_mail = true
        }
        if(!systemSettingData.bank_early_payment_service_agent_flag){
            bankEconomicInsert.early_payment_flag = false
        }
        if(systemSettingData.bank_early_payment_service_agent_flag && !systemSettingData.bank_early_payment_service_merchant_flag){
            bankEconomicInsert.early_payment_flag = true
        }
        //type追加
        creditSystemInsert.type = systemSettingData.credit_payment_type
        bankSystemInsert = {...bankSystemInsert, type:systemSettingData.bank_payment_type}
        cvsSystemInsert = {...cvsSystemInsert, type:systemSettingData.cvs_payment_type}
        //cvv_setting追加
        creditSystemInsert.cvv_setting = systemSettingData.credit_cvv_setting_agent
        // cgi_param追加
        if(systemSettingData.credit_cgi_agent_flag){
            creditSystemInsert.cgi_param = systemSettingData.credit_cgi_param_agent
        }
        if(systemSettingData.bank_cgi_agent_flag){
            bankSystemInsert.cgi_param = systemSettingData.bank_cgi_param_agent
        }
        if(systemSettingData.cvs_cgi_agent_flag){
            cvsSystemInsert.cgi_param = systemSettingData.cvs_cgi_param_agent
        }
        economicInsert = {...economicInsert, credit:{economic_requirement:creditEconomicInsert, system_setting:creditSystemInsert}, bank:{economic_requirement:bankEconomicInsert, system_setting:bankSystemInsert},cvs:{economic_requirement:cvsEconomicInsert, system_setting:cvsSystemInsert},atobarai:{economic_requirement:atobaraiEconomicInsert, system_setting:atobaraiSystemInsert} }

        return economicInsert
    }
    //システムセッティングマスターセットハンドラ
    const systemSettingMasterSetHandler = (response) => {
        let systemSettingData = {}
        const systemSettingMasters = response.response_data.masters.system_setting_masters
        for(let mainDiv in systemSettingMasters){
            for(let secoundDiv in systemSettingMasters[mainDiv]){
                if(secoundDiv === 'payment_type'){
                    let paymentName = mainDiv + '_' + secoundDiv
                    systemSettingData = {...systemSettingData, [paymentName]:systemSettingMasters[mainDiv][secoundDiv]}
                }else{
                    for(let item in systemSettingMasters[mainDiv][secoundDiv]){
                        let isFlag = item.includes('_flag')
                        let noFlagName = item.replace('_flag','')
                        let itemName = mainDiv + '_' + noFlagName + '_' + secoundDiv
                        itemName = isFlag ? itemName + '_flag' : itemName
                        systemSettingData = {...systemSettingData,[itemName]:systemSettingMasters[mainDiv][secoundDiv][item]}
                    }
                }
            }
        }
        return systemSettingData
    }
    //経済条件マスタに項目がない場合表示しない制御をするフラグ
    const setServiceShowFlagHandler = (masterData) => {
        let economicDividedObj = {
            credit:false,
            bank:false,
            cvs:false,
            atobarai:false,
        }
        for(let service in masterData){
            economicDividedObj = {...economicDividedObj, [service]:true}
        }
        return economicDividedObj
    }

    // ログインに成功したらダッシュボードにページ遷移
    const LoginMethod = async (email, password) => {
        const validateMail = mailValidate(email)
        const validatePassword = singlePasswordValidate(password)
        setErrMsg({ email: '', password: '' })

        if (!validateMail.isValidate || !validatePassword.isValidate) {
            const errorMessage = {}
            errorMessage.email = validateMail.message;
            errorMessage.password = validatePassword.message;
            setErrMsg(errorMessage)
            return
        }
        loginData = {
            "email": email,
            "password": password
        }
        //API通信の間ローディング画面を表示するフラグをONにする
        setIsLoading(true,dispatch)
        const loginResponse = await PostMethod(loginData, '/login');
        return loginResponse;
    }
    //正常時処理
    const normal = (response) => {
        const systemSettingData = systemSettingMasterSetHandler(response)
        const {merchantData,representativeData,operatorData,bankObj,reviewContentInsert} = entryDataSuitHandler(response)
        const economicConditionMasterData = economicConditionMasterInsertHandler(response)
        const reviewContentMasterPlusShopAddition = reviewContentInsertHandler(response,reviewContentInsert)
        let preEconomicData = economicInsertHandler(response)
        //経済条件マスタに項目がない場合表示しない制御をするフラグ
        const economicDividedData = setServiceShowFlagHandler(economicConditionMasterData)        
        const economicData = setEconomicFlagBySystemSetting(systemSettingData,preEconomicData)

        //ユーザリコイルセット
        setUserInfoData({...userInfoData, email:loginData.email,user_id:response.response_data.user_id, entry_id:response.response_data.entry_id, agent_master_id:response.response_data.agent_master_id})
        //エージェントマスターリコイルセット
        setAgentMasterInfoData({...agentMasterInfoData, ...response.response_data.masters.agent_master})
        //システム設定情報マスタリコイルセット
        setSystemSettingMasterInfoData({...systemSettingMasterInfoData,...systemSettingData})
        //ショップ情報マスタリコイルセット
        setReviewContentMasterInfoData({...reviewContentMasterInfoData,...response.response_data.masters.shop_addition_masters})
        //経済条件マスタリコイルセット    
        setEconomicConditionMasterInfoData(economicConditionMasterData)
        //ショップ情報の格納
        setShopInfoData({...shopInfoData, ...reviewContentMasterPlusShopAddition})
        //経済条件にマスタの値を挿入
        setEconomicInfoData(economicData)
        //お客様情報リコイルセット
        setMerchantInfoData({...merchantInfoData,...merchantData})
        //代表者情報リコイルセット
        setRepresentativeInfoData({...representativeInfoData,...representativeData})
        //担当者情報リコイルセット
		setOperationInfoData({ ...operationInfoData,...operatorData })
        //口座情報リコイルセット
		setBankAccountInfoData({ ...bankAccountInfoData,...bankObj })
        //ファイル情報リコイルセット
        setFileInfoData({...fileInfoData, ...response.response_data.entry_info_file})
        //マスタによる経済条件だしわけ情報
        setEconomicDevidedInfoData(economicDividedData)
        loginData = {
            err_message: '',
            code: response.code
        }
        
        //ログインチェックで使用
        setLoginInfoData(loginData)
        //ローディング画面を非表示
        setIsLoading(false,dispatch)

        return navigate('/dashboard', {state:{routeTo:'dashboard'}})
    }

    //異常時処理
    const abnormal = (response) => {
        let loginData = {
            err_message: <ToolChipDiv>{response.message}</ToolChipDiv>,
            code: response.code
        }
        if(response.code === 403){
            dispatch({
                type: 'set-context-data',
                payload: {
                    auth: loginData
                }
            })
            setIsClosed(true)
        }
        //ログインチェックで使用
        setSession('login', loginData)
        //ローディング画面を非表示
        setIsLoading(false,dispatch)
        return navigate('/login')
    }

    const setPasswordHandler = (e) => {
        return setPassword(e.target.value)
    }

    const setEmailHandler = (e) => {
        return setEmail(e.target.value)
    }

    return { LoginMethod, EmailData, PasswordData, isClosed, normal, abnormal, setPasswordHandler, setEmailHandler, ErrMsgs }
}

export default useLoginHooks