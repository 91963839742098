import styled from "styled-components"
import AnotherTabLink from "../components/ui-parts/AnotherTabLink";

export const ToolChipDiv = styled.div`
    white-space: pre-line;
    text-align:left;
`;
export const toolChipList = {
    merchant_type: '法人の場合は法人を、個人の場合は個人を選んでください',
    merchant_name_title: '商業登記簿謄本に記載されている商号（会社名）をご入力ください。',
    merchant_name: '※全角のみ入力可',
    merchant_name_kana: '※半角カナのみ入力可',
    merchant_contractor_name: 'ご提出いただく本人確認資料に記載されている氏名をご入力ください。',
    merchant_phone_number: "固定電話番号をご登録ください。※固定電話番号がない場合は携帯電話番号可。フリーダイヤル不可。",
    merchant_post_code_empty_error: "正しい郵便番号を入力してください",
    merchant_address_streetbunch:'※漢字/カナ/ひらがな/数字/英字/ハイフン 50文字以下で入力',
    merchant_address_building:'建物名が無い場合は入力不要です',
    merchant_address_municiparity_kana:'※半角カナのみ入力可',
    merchant_address_streetbunch_kana:'※半角カナ・半角数字・ハイフンのみ入力可',
    merchant_address_building_kana:'※半角カナ・半角数字・ハイフンのみ入力可',
    url_attention:'※「http://」「https://」から入力してください',
    merchant_address_corporate: <><ToolChipDiv>{'商業登記簿謄本に記載されている本店住所をご入力ください。\n※郵便番号がわからない場合は'}<AnotherTabLink href={"https://www.post.japanpost.jp/zipcode/"} >こちら</AnotherTabLink>{'をご参照ください。'}</ToolChipDiv></>,
    merchant_address_contractor: 'ご提出いただく本人確認資料に記載されている住所をご入力ください。',
    merchant_identity_doc_images: "本人確認資料の種類 https://www.cardservice.co.jp/info/guide/kakuninshorui.html",
    merchant_annual_business: '開業1年未満の場合は見込み額をご入力ください。',
    merchant_website_url:"今回お申し込みのショップ以外に、コーポレートページ等をお持ちでしたらご入力ください" ,
    representative_name: '商業登記簿謄本に記載されている代表者氏名をご入力ください。',
    name_attention: <>「髙(はしごだか)」「﨑(たつさき)」等、一部ご利用いただけない文字がございます。<br className="always"/>なお、姓名にご利用いただけない文字が含まれている場合、ゼウスにて修正させていただくことがございます。</>,
    operator_name: <ToolChipDiv>{'ゼウスからご契約者様へご連絡を差し上げる際の連絡先を入力してください。\n（ご連絡する内容の例）\n・お申込内容の確認\n・審査手続きに関し不明点の確認やサイト修正のお願い\n・カード会社等からの利用内容確認\n・決済システム障害報\n・その他必要に応じて'}</ToolChipDiv>,
    operator_email: <ToolChipDiv>{'※指定できるメールアドレスは1つのみです。\n※半角小文字で入力'}</ToolChipDiv>,
    support_operator_contact: <ToolChipDiv>{'「特定商取引法に基づく表記 」に掲載されている連絡先をご入力ください。\n※コンビニ決済サービスをお申込みの場合はコンビニ払い払込票（お客様向け）にも表示されます。'}</ToolChipDiv>,
    merchant_corporate_number: '法人番号（13桁）がご不明の場合は、下記の国税庁のサイトにてお調べいただけます。',
    merchant_annual_business_explain: '※桁区切りの「 , 」は不要、数字のみ入力可',
    bank_account_info: <>【ご注意】ご指定いただける<AnotherTabLink href="https://www.cardservice.co.jp/info/guide/kouza.html">口座情報</AnotherTabLink>について事前にご確認ください。</>,
    bank_account_number: '※口座番号が6桁以下の場合は先頭に「0」を入力し7桁で入力してください。',
    bank_account_name: <ToolChipDiv>{'※ご契約者様と同一名義の口座をご指定ください。\n※利用可能な文字：半角カタカナ / 半角英字（大文字） / 半角数字 / 半角記号\n※利用可能な記号：丸カッコ「( )」、スラッシュ「/」  、ハイフン「-」、ピリオド「.」、スペース\n※長音はハイフン「-]で入力してください。'}</ToolChipDiv>,
    bank_account_name_pulldown: <ToolChipDiv>{'金融機関名に含まれる文字を1文字（漢字・ひらがな・全角英字 等）以上入力してください'}</ToolChipDiv>,
    bank_account_branch_pulldown: <ToolChipDiv>{'支店名に含まれる文字を1文字（漢字・ひらがな・英字 等）以上入力してください'}</ToolChipDiv>,
    passbook_images:<ToolChipDiv>{'「a 金融機関名」「b 支店名」「c 口座種別」「d 口座番号」「e 受取人口座名義（カナ表記）」が記載されたページの写し\nー通帳：通帳見開き１ページ目のコピー\nーネットバンク：ログイン後の画面で、a～eが確認できるページのキャプチャ'}</ToolChipDiv>,
    shop:<>店舗名欄の登録内容はお客様のご利用代金明細に表示されますので、わかりやすい名称でご登録ください。</>,
    shop_name:<ToolChipDiv>{'※漢字/カナ/ひらがな/英数/スペース/記号　50文字以内で入力\n※英数字・記号は半角で入力\n※記号は「-」,「.」のみ入力可'}</ToolChipDiv>,
    shop_name_kana:<ToolChipDiv>{'※半角カナ、半角スペースのみ入力可'}</ToolChipDiv>,
    shop_name_alphanumeric:<ToolChipDiv>{'※半角英字（大文字）/半角数字/半角スペース/半角記号　25文字以内で入力\n※記号は「-」,「.」のみ入力可'}</ToolChipDiv>,
    shop_addition_site_status:<ToolChipDiv>{'【オープン済み】：お申込みのショップURLから、カートもしくは申込フォームで、商品の購入・サービスの申込が可能な状態の場合は、こちらをご選択ください。\n\n【準備中】：お申込みのショップURLから、商品・サービス内容と価格、特定商取引法に基づく表記などが確認出来ない場合は、こちらを選択いただき、サンプルサイト(デモサイト)URLもしくは、実際にオープン後に掲載予定の内容を(商品・サービス内容と価格、特定商取引法に基づく表記)資料にてご提出ください。'}</ToolChipDiv>,
    shop_website_url: <ToolChipDiv>{'ショップURLは、カード会社等に申請するショップ稼働時のURLです。\n構築中の場合でも、ショップ稼働時のURLをご記入ください。\nデモサイト等のURLではございません。'}</ToolChipDiv>,
    shop_addition_shop_website_id_password: <>IDとパスワードは/で区切って入力してください</>,
    shop_name_alphabet: <ToolChipDiv>{'海外発行カードのお客様のご利用代金明細にはアルファベットで表示されます。\n貴店の英語表記名をご入力ください。\n英語表記名がない場合はローマ字にてご登録をお願いします。'}</ToolChipDiv>,
    shop_cvs_website: <ToolChipDiv>{'コンビニのマルチ端末から出力される払込票（お客様向け）に「ご購入ショップ名」として印字されますので、わかりやすい名称でご登録ください。\n会社名を指定される場合、法人格は利用不可のため削除されますのでご入力は避けてください。'}</ToolChipDiv>,
    shop_cvs_website_name: <ToolChipDiv>{'※「株式会社」等の法人格は、省いてください\n※全角漢字/全角カナ/ひらがな/全角英数/全角記号　12文字以内で入力\n※利用できない記号「!#$%()~|-^@[:]./`{ *}?_+=」'}</ToolChipDiv>,
    shop_cvs_website_name_kana: <ToolChipDiv>{'※「ｶﾌﾞｼｷｶﾞｲｼｬ」等の法人格は、省いてください\n※半角カナ  24文字以内で入力'}</ToolChipDiv>,
    shop_addition_product_content_code: <ToolChipDiv>{'物販：実際の現物商品の提供\n役務：サービスなど、無形商材の提供 \nデジタルコンテンツ：デジタルで複製が可能な無形商材の提供'}</ToolChipDiv>,
    shop_addition_product_content_freetext: '※決済対象となる商品内容・サービス内容をご入力ください。',
    antique_select :'決済対象に中古品のお取り扱いがある場合、「あり」をご選択ください',
    antique: 'ご契約者様名義の許可証の写しをご提出ください。',
    shop_addition_prepaid_flag: 'コース・回数券等のサービス提供期間が2か月以上の料金を、お申込時に一括でお支払いいただく提供方法です。',
    shop_addition_subscription_flag: '定期購入や月会費など、購入者様のアクション無に定期的な引落を行う販売・課金方式です。',
    shop_addition_point_purchase_flag: 'ポイントを事前に購入した上で、商品またはサービスを利用する提供方法です。',
    shop_addition_rental_product_url: '規約・注意事項のURLまたはファイルをご提出ください。',
    shop_addition_reservation_period_flag: '注文から商品発送またはサービス提供されるまでの期間を入力してください。',
    shop_addition_physical_store_flag: <ToolChipDiv>{'下記①②の条件に当てはまる実店舗をお持ちの場合は【あり】をご選択ください。それ以外の場合は【なし】をご選択ください。\n\n①今回ご申請のショップで販売される商品と同じ商品を、日常的に販売されている対面の店舗がある\n\n②今回ご申請のショップ名と同じ看板が確認できる対面の店舗がある'}</ToolChipDiv>,
    shop_addition_physical_store_url: '実店舗の外観や案内等が確認できるURLをご提出ください。',
    license: 'ご契約者様名義の許可証をご提出ください。',
    shop_addition_category: 'お申込みサービスの決済対象となる商品・サービスに該当するカテゴリーをご選択ください。',
    shop_atobarai_customer_notice_store_name:"コンビニあと払いをご利用のお客様への請求メールやお支払い時の電子バーコード画面に表示されますので、わかりやすい名称でご登録ください。",
    shop_atobarai_customer_notice_store_name_explain:<ToolChipDiv>{'※漢字/カナ/ひらがな/英数  15文字以内で入力可\n※利用できない記号「‥′″※、スペース」'}</ToolChipDiv>,
    shop_addition_available_product_flag:<ToolChipDiv>{`取扱不可商材の取扱いはありません。(`}<AnotherTabLink href="https://www.cardservice.co.jp/guide/preparation.html#anchor-08">取扱不可商材について</AnotherTabLink>{')'}</ToolChipDiv>,
    business_operator_fee:"決済サービス提供元の事業者が直接加盟店に請求する取引手数料となります。",
    cvs_fee:"ユーザの決済対象額に応じて取引手数料が異なります。",
    transaction_fee:"ゼウスが加盟店様に請求する取引手数料となります。",
    claim_fee:"コンビニあと払いをご利用のお客様への請求メール送信料金となります。（決済ごとに発生します。）",
    threed_secure:<ToolChipDiv>{'3Dセキュアとは、クレジットカード決済における不正利用の防止対策として、ビザ・インターナショナルが開発した本人認証の仕組みです。\nカード発行会社が直接購入者の本人認証を行うため、ネットショップ側はなりすましなどの不正利用のリスクを大幅に軽減することができます。'}</ToolChipDiv>,
    cvv:<ToolChipDiv>{'クレジットカードの裏面または表面に記載された3桁もしくは4桁の番号を指し、カード会社により表示箇所および名称が異なります。\nクレジットカードによる決済処理の際に、クレジットカード番号とは別のセキュリティコードを入力することで不正利用リスクの軽減を図ることができます。'}</ToolChipDiv>,
    subscription:<ToolChipDiv>{'2回目以降の決済時にクレジットカード番号の入力が不要です。リピーター様向けの決済としてご利用いただけます。'}</ToolChipDiv>,
    send_mail:<ToolChipDiv>{'ユーザー様がクレジット決済をされた都度、加盟店様へ自動的に送信している決済結果通知メールです。'}</ToolChipDiv>,
    id_password:<ToolChipDiv>{'CGIをお返しすることも可能です。決済結果を受け取るCGIについては加盟店様側での構築が必要となります。'}</ToolChipDiv>,
    customer_support:<ToolChipDiv>{'24時間365日年中無休にてエンドユーザー様のご質問にご対応をさせていただきます。'}</ToolChipDiv>,
    payment_now:<ToolChipDiv>{'売上管理画面より継続予約登録後、すぐに決済が開始されます。'}</ToolChipDiv>,
    early_payment:<ToolChipDiv>{'クレジットカード決済における通常の入金サイクルが月末締め、翌月末払い（または、月末締め、翌月20日払い）のところ、早期入金サービスなら月2回入金、月3回入金など事業者様のご希望でお選びいただくことが可能です。'}</ToolChipDiv>,
    auth:<ToolChipDiv>{'注文と売上処理にタイムラグが発生する場合に90日以内に売上確定を行います。(±5,000円の調整可能)'}</ToolChipDiv>,
    cgi:<ToolChipDiv>{'CGIをお返しすることも可能です。決済結果を受け取るCGIについては加盟店様側での構築が必要となります。'}</ToolChipDiv>,
    terms_of_used:<ToolChipDiv>{'・本画面にて弊社が取得するお客様の個人情報は以下に従い適切にお取扱いいたします。\n・ご提供いただきました個人情報は、以下のためにのみ利用いたします。\n　　1．お問い合わせに対する回答\n　　2．WEB申込画面のログイン等のご案内\n・ご提供いただきました個人情報は、法令に基づく場合を除き、お客様の承諾なしに第三者（弊社委託先を除く）へ提供することはありません。\n・ご提供いただきました個人情報の取扱いを第三者に委託することがあります。\n・個人情報の提供はお客様の任意判断によりますが、ご提供いただけない場合、お客様の求められるサービス・対応が受けられない場合があります。\n\n※ご提供いただいた個人情報の取扱い、苦情及びご相談、各種ご請求（利用目的の通知、開示、訂正・追加・削除、利用または提供の拒否等）に関するお問合せ先\n\n株式会社ゼウス\n個人情報保護管理者　代表取締役\n個人情報お問い合わせ窓口\n〒150-0002　東京都渋谷区渋谷2-1-1\nprivacy@cardservice.co.jp\n(お問い合わせ窓口営業時間 平日9:00～17:30(年末年始除く))'}</ToolChipDiv>,
    user_regist_usabled_symbol:<ToolChipDiv>{'使用可能な記号は以下になります\n^ $ * . [ ] { } ( ) ? - " ! @ # % & / , > < : ; | _ ~ ` + ='}</ToolChipDiv>
}

//申し込みトップ画面文言
export const entryTopPhrases = {
    merchant:<ToolChipDiv>{'お客様の情報を入力してください。\n\nご登録いただいた情報と本人確認書類内容が一致しない場合は、お申込みが無効になる場合がございます。\n\n※'}<AnotherTabLink href="https://www.cardservice.co.jp/info/guide/kakuninshorui.html">本人確認書類</AnotherTabLink>{'について'}</ToolChipDiv>,
    bank:<ToolChipDiv>{'ゼウスから売上金をお振込みする口座情報を入力してください。\n\n※'}<AnotherTabLink href="https://www.cardservice.co.jp/info/guide/kouza.html">口座確認書類</AnotherTabLink>{'について'}</ToolChipDiv>,
    shop:"決済サービス導入のためのショップ情報を入力してください。",
}

//業種
export const industryObj = {1 :'小売業',2:'飲食関連',3:'インターネット関連',4:'情報・通信業',5:'サービス業（人的）',6:'サービス業（施設）',7:'サービス業（その他）',8:'教育関連',9:'スクール',10:'不動産関連',11:'運輸業',12:'医療関連業',13:'保険業',14:'冠婚葬祭',15:'寄付・募金',16:'行政・公共料金',17:'チケット・金券・ポイント'}

//支払方法プルダウン
export const selectItemsPaymentMethod = [
    { label: '選択してください', value: '', disabled: false },
    { label: '月々クレジットカード払い', value: '1', disabled: false },
    { label: '年間一括銀行振り込み払い', value: '3', disabled: false },
]

//アカウントIDタイトル
export const accountIdPhrase = {
    1 :'おちゃのこネットのアカウントID',
}


// 利用開始画面おまかせ入金支払い対象パターン
export const bankPaymentPattern = {
    1 : {
        deposit_target: "入金対象",
        insufficient_amount: "入金対象外",
        overdeposit: "入金対象外",
        early_payment: "入金対象外",
        payment_due_date: "７日"
    }
}
// 利用開始画面クレジット支払い対象パターン
export const cvsPaymentPattern = {
    1 : {
        payment_due_date: "７日"
    }
}

export const entryAPIForm = {
    entry_id:12345,
    user_id: 12345,
    service_code_list:"credit/bank",
    merchant: {
        // merchant_id:"",
        merchant_type: "",
        merchant_name: '',
        merchant_name_kana: '',
        merchant_last_name: "",
        merchant_first_name: "",
        merchant_last_name_kana: "",
        merchant_first_name_kana: "",
        merchant_phone_number: "",
        merchant_register_post_code: "",
        merchant_address_prefectures: "",
        merchant_address_municipality: "",
        merchant_address_streetbunch: "",
        merchant_address_prefectures_kana: "",
        merchant_address_municipality_kana: "",
        merchant_address_streetbunch_kana: "",
        merchant_website_url: "",
        merchant_annual_business: "",
        merchant_shop_category: "",
        merchant_corporate_number: "",
        representative_last_name: "",
        representative_first_name: "",
        representative_last_name_kana: "",
        representative_first_name_kana: "",
        representative_sex: "",
        representative_birthday: "",
        representative_position_name: "",
        operator_last_name: "",
        operator_first_name: "",
        operator_last_name_kana: "",
        operator_first_name_kana: "",
        operator_email: "",
        operator_phone_number: "",
        support_operator_email: "",
        support_operator_phone_number: "",
        bank_account_category: "",
        bank_account_number: "",
        bank_account_name: "",
        bank_account_bank_name: "",
        bank_account_bank_branch: "",
        bank_account_bank_code: "",
        bank_account_bank_branch_code: "",
    },
    files: {
        identity_doc_images: [],
        passbook_images: [],
        antique: [],
        rental: [],
        license: [],
        examination_document: []
    },
    shop: {
        shop_name: "",
        shop_name_kana: "",
        shop_name_alphabet: "",
        shop_cvs_website_name: "",
        shop_cvs_website_name_kana: "",
        shop_website_url: "",
        shop_addition_shop_website_check_url: "",
        shop_addition_service_start_plan_date: "",
        shop_addition_price_range_min: "",
        shop_addition_price_range_max: "",
        shop_addition_product_content_code: '',
        shop_addition_major_content: "",
        shop_addition_major_content_code: "",
        shop_addition_minor_product_type: "",
        shop_addition_medium_content: "",
        shop_addition_medium_content_code: "",
        shop_addition_product_content_freetext: "",
        shop_addition_license_flag: false,
        shop_addition_antique_flag: false,
        shop_addition_high_risk_flag: false,
        shop_addition_prepaid_flag: false,
        shop_addition_subscription_flag: false,
        shop_addition_subscription_code: "",
        shop_addition_point_purchase_flag: false,
        shop_addition_reserved_product_flag: false,
        shop_addition_reservation_period_flag: false,
        shop_addition_estimated_product_flag: false,
        shop_addition_rental_flag: false,
        shop_addition_rental_product_url: "",
        shop_addition_online: false,
        shop_addition_offline: false,
        shop_addition_btob_code: '',
        shop_addition_btob_special_rate_flag: false,
        shop_addition_non_infringing_product_presence_absence_flag: false,
        shop_addition_available_product_flag: false,
        shop_addition_physical_store_flag: false,
        shop_addition_physical_store_url_flag: false,
        shop_addition_physical_store_url: "",
        shop_addition_business_deal_url: "",
        shop_addition_use_continuation: false,
        shop_addition_conduct_start_yyyy: "",
        shop_addition_conduct_start_mm: "",
        shop_addition_case_load_1_month_ago: false,
        shop_addition_can_do_without_seven: false,
        shop_addition_non_administrative_penalties_five_year_flag: false,
        shop_addition_non_losing_judgment_flag: false,
        shop_addition_site_status: "",
        shop_addition_payment_timing: "",
        shop_addition_special_for_sale: false,
        shop_addition_content_special_for_sale: false,
        shop_addition_cancel_halfway: false,
        shop_addition_card_number_pass_flag: false,
        shop_addition_card_number_pass_ym: "",
        shop_addition_pcidss_protection_flag: false,
        shop_addition_pcidss_protection_ym: "",
        shop_addition_authentication_imple_flag: false,
        shop_addition_authentication_imple_ym: "",
        shop_addition_security_code_imple_flag: false,
        shop_addition_security_code_imple_ym: "",
        shop_addition_injustice_address_using_flag: false,
        shop_addition_injustice_address_using_ym: "",
        shop_addition_behavior_analysis_imple_flag: false,
        shop_addition_behavior_analysis_imple_ym: "",
        shop_addition_other_measures_flag: false,
        shop_addition_other_measures_ym: "",
        shop_addition_other_measures_comment: "",
        shop_addition_door_to_door_sales_flag: false,
        shop_addition_telemarketing_sales_flag: false,
        shop_addition_multilevel_marketing_sales_flag: false,
        shop_addition_bor_sales_transactions_flag: false,
        shop_addition_specified_continuous_service_flag: false
    },
    services: {
        credit: {
            zeus_incentive_id: 1234,
            system_setting: {
                type: "",
                cgi: false,
                div: false,
                threed_secure: false,
                cvv: false,
                cvv_setting: "",
                subscription: false,
                auth: false,
                send_mail: false,
            },
            economic_requirement: {
                payment_site: "",
                early_payment_flag: false,
                opening_contract_fee: "",
                jcb_fee: "",
                vm_fee: "",
                diners_fee: "",
                sales_processing_fee: "",
                system_usage_fee_cvv: "",
                system_usage_fee_3d_secure: "",
                system_usage_fee_confirmation_email: "",
                system_usage_fee_continuous_billing: "",
                system_usage_fee_payment_now: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_early_payment_service: "",
                system_usage_fee_div2_bonus: "",
                system_usage_fee_id_password_storage: "",
                monthly_payment_method: "1",
                monthly_option_fee_cvv: "",
                monthly_option_fee_3d_secure: "",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_continuous_billing: "",
                monthly_option_fee_payment_now: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                monthly_option_fee_div2_bonus: "",
                monthly_option_fee_id_password_storage: "",
                option_fee: "",
            }
        },
        bank: {
            zeus_incentive_id: 1234,
            system_setting: {
                type: "",
                cgi: false,
            },
            economic_requirement: {
                payment_site: "endOfMonthAndEndOfMonth",
                early_payment_flag: false,
                fee: "",
                opening_contract_fee: "",
                sales_processing_fee: "",
                system_usage_fee_confirmation_email: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_early_payment_service: "",
                monthly_payment_method: "1",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                option_fee: "",
            }
        },
        cvs: {
            zeus_incentive_id: 1234,
            system_setting: {
                type: "",
                cgi: false,
            },
            economic_requirement: {
                payment_site: "endOfMonthAndEndOfMonth",
                early_payment_flag: false,
                opening_contract_fee: "",
                system_usage_fee_confirmation_email: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_payment_now: "",
                fee_range_2000: "",
                fee_range_3000: "",
                fee_range_5000: "",
                fee_range_7000: "",
                fee_range_10000: "",
                fee_range_30000: "",
                fee_range_50000: "",
                fee_range_100000: "",
                fee_range_200000: "",
                fee_range_300000: "",
                sales_processing_fee: "",
                monthly_payment_method: "1",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                option_fee: "",
            }
        }
    }
}

export const entryAPINoErrorForm = {
    entry_id:12345,
    user_id: 12345,
    service_code_list:"credit/bank",
    merchant: {
        // merchant_id:"",
        merchant_type: "1",
        merchant_name: '青木商事',
        merchant_name_kana: 'アオキショウジ',
        merchant_last_name: "青木",
        merchant_first_name: "庄司",
        merchant_last_name_kana: "アオキ",
        merchant_first_name_kana: "ショウジ",
        merchant_phone_number: "0120-828-828",
        merchant_register_post_code: "1234567",
        merchant_address_prefectures: "東京",
        merchant_address_municipality: "東京区東京",
        merchant_address_streetbunch: "京番地10",
        merchant_address_prefectures_kana: "トウキョウ",
        merchant_address_municipality_kana: "トウキョウクトキョウ",
        merchant_address_streetbunch_kana: "キョウバンチ10",
        merchant_website_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        merchant_annual_business: "1000",
        merchant_shop_category: "商社",
        merchant_corporate_number: "1234567891234",
        // representative_id: "1234",
        representative_last_name: "青木",
        representative_first_name: "庄司",
        representative_last_name_kana: "アオキ",
        representative_first_name_kana: "ショウジ",
        representative_sex: "male",
        representative_birthday: "1980/01/01",
        // representative_register_post_code: "",
        // representative_address_prefectures: "",
        // representative_address_municipality: "",
        // representative_address_streetbunch: "",
        // representative_address_kana_prefectures: "",
        // representative_address_kana_municipality: "",
        // representative_address_kana_streetbunch: "",
        representative_position_name: "代表取締役社長",
        // operator_id: "1234",
        operator_director_last_name: "青木",
        operator_director_first_name: "庄司",
        operator_director_last_name_kana: "アオキ",
        operator_director_first_name_kana: "ショウジ",
        operator_last_name: "青木",
        operator_first_name: "庄司",
        operator_last_name_kana: "アオキ",
        operator_first_name_kana: "ショウジ",
        operator_email: "shojiaoki@gmail.com",
        operator_phone_number: "080-8888-8888",
        support_operator_email: "tomoharuaoki@gmail.com",
        support_operator_phone_number: "080-7777-7777",
        // bank_account_id: "1234",
        bank_account_category: "1",
        bank_account_number: "1239999",
        bank_account_name: "アオキショウジ",
        bank_account_bank_name: "三井住友銀行",
        bank_account_bank_branch: "与那国島支店",
        bank_account_bank_code: "1234",
        bank_account_bank_branch_code: "123",
    },
    files: {
        identity_doc_images: [],
        passbook_images: [],
        antique: [],
        rental: [],
        license: [],
    },
    shop: {
        account_id:123,
        shop_name: "庄司WEB店",
        shop_name_kana: "ショウジウェブテン",
        shop_name_alphabet: "shoji web shop",
        shop_cvs_website_name: "庄司WEB店",
        shop_cvs_website_name_kana: "ショウジウェブテン",
        shop_website_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        shop_addition_shop_website_check_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        shop_addition_service_start_plan_date: "",
        shop_addition_price_range_min: "1",
        shop_addition_price_range_max: "10000",
        shop_addition_product_content_code: '',
        shop_addition_major_content: "大項目A",
        shop_addition_major_content_code: "A125",
        shop_addition_minor_product_type: "",
        shop_addition_medium_content: "中項目A",
        shop_addition_medium_content_code: "B125",
        shop_addition_product_content_freetext: "陶器",
        shop_addition_license_flag: true,
        shop_addition_antique_flag: true,
        shop_addition_high_risk_flag: true,
        shop_addition_prepaid_flag: true,
        subscription_flag: true,
        shop_addition_subscription_code: "",
        shop_addition_point_purchase_flag: true,
        shop_addition_reserved_product_flag: true,
        shop_addition_reservation_period_flag: "",
        shop_addition_estimated_product_flag: true,
        shop_addition_rental_flag: true,
        shop_addition_rental_product_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        shop_addition_online: true,
        shop_addition_offline: true,
        shop_addition_btob_code: 1,
        shop_addition_btob_special_rate_flag: true,
        shop_addition_non_infringing_product_presence_absence_flag: true,
        shop_addition_available_product_flag: true,
        shop_addition_physical_store_flag: true,
        shop_addition_physical_store_url_flag: true,
        shop_addition_physical_store_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        shop_addition_business_deal_url: "https://mainentry-ikebe.dev002.finsol.work/entry_confirm",
        shop_addition_use_continuation: false,
        shop_addition_conduct_start_yyyy: "",
        shop_addition_conduct_start_mm: "",
        shop_addition_case_load_1_month_ago: false,
        shop_addition_can_do_without_seven: false,
        shop_addition_non_administrative_penalties_five_year_flag: true,
        shop_addition_non_losing_judgment_flag: true,
        shop_addition_site_status: 1,
        shop_addition_payment_timing: "",
        shop_addition_special_for_sale: false,
        shop_addition_content_special_for_sale: false,
        shop_addition_cancel_halfway: false,
        shop_addition_card_number_pass_flag: false,
        shop_addition_card_number_pass_ym: "",
        shop_addition_pcidss_protection_flag: false,
        shop_addition_pcidss_protection_ym: "",
        shop_addition_authentication_imple_flag: false,
        shop_addition_authentication_imple_ym: "",
        shop_addition_security_code_imple_flag: false,
        shop_addition_security_code_imple_ym: "",
        shop_addition_injustice_address_using_flag: false,
        shop_addition_injustice_address_using_ym: "",
        shop_addition_behavior_analysis_imple_flag: false,
        shop_addition_behavior_analysis_imple_ym: "",
        shop_addition_other_measures_flag: false,
        shop_addition_other_measures_ym: "",
        shop_addition_other_measures_comment: "",
        shop_addition_door_to_door_sales_flag: false,
        shop_addition_telemarketing_sales_flag: false,
        shop_addition_multilevel_marketing_sales_flag: false,
        shop_addition_bor_sales_transactions_flag: false,
        shop_addition_specified_continuous_service_flag: false
    },
    services: {
        credit: {
            zeus_incentive_id: 1234,
            system_setting: {
                //仮
                select_flag:true,
                type: "",
                cgi: true,
                div: false,
                threed_secure: true,
                cvv: false,
                cvv_setting: "",
                subscription: false,
                auth: false,
                send_mail: false,
            },
            economic_requirement: {
                payment_site: "",
                early_payment_flag: false,
                opening_contract_fee: 20,
                jcb_fee: 20,
                vm_fee: 20,
                diners_fee: 20,
                sales_processing_fee: 20,
                system_usage_fee_cvv: "",
                system_usage_fee_3d_secure: "",
                system_usage_fee_confirmation_email: "",
                system_usage_fee_continuous_billing: "",
                system_usage_fee_payment_now: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_early_deposit_service: "",
                system_usage_fee_div2_bonus: "",
                system_usage_fee_id_password_storage: "",
                monthly_payment_method: "",
                monthly_option_fee_cvv: "",
                monthly_option_fee_3d_secure: "",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_continuous_billing: "",
                monthly_option_fee_payment_now: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                monthly_option_fee_div2_bonus: "",
                monthly_option_fee_id_password_storage: "",
                option_fee: 20,
            }
        },
        bank: {
            zeus_incentive_id: 1234,
            system_setting: {
                //仮
                select_flag:false,
                type: "",
                cgi: false,
            },
            economic_requirement: {
                payment_site: "",
                early_payment_flag: false,
                fee: 20,
                opening_contract_fee: 20,
                sales_processing_fee: 20,
                system_usage_fee_confirmation_email: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_early_deposit_service: "",
                monthly_payment_method: "",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                option_fee: 20,
            }
        },
        cvs: {
            zeus_incentive_id: 1234,
            system_setting: {
                //仮
                select_flag:false,
                type: "",
                cgi: false,
            },
            economic_requirement: {
                payment_site: "",
                early_payment_flag: false,
                opening_contract_fee: 20,
                system_usage_fee_confirmation_email: "",
                system_usage_fee_customer_support: "",
                system_usage_fee_early_deposit_service: "",
                fee_range_2000: "",
                fee_range_3000: "",
                fee_range_5000: "",
                fee_range_7000: "",
                fee_range_10000: "",
                fee_range_30000: "",
                fee_range_50000: "",
                fee_range_100000: "",
                fee_range_200000: "",
                fee_range_300000: "",
                sales_processing_fee: 20,
                monthly_payment_method: "",
                monthly_option_fee_confirmation_email: "",
                monthly_option_fee_customer_support: "",
                monthly_option_fee_early_deposit_service: "",
                option_fee: 20,
            }
        }
    }
}

export const loginAPIData = {
    agent_service_id:"",
    code: 200,
    massege: "AccessToken発行済み。",
    response_data:{
        user_id: 1,
        master:{
            agent_master: {
                id:1,
                zeus_agent_service_id: "",
                zeus_agent_deal_id: "",
                zeus_agent_id: "",
                account_id_flag:false,
                demand_code: "option",
            },
            credit_setting_masters:{
                agent_master_id: 1, 
                payment_site:"",
                early_payment_two:1000,
                early_payment_three:2000,
                opening_contract_fee:3000,
                jcb_fee:"",
                vm_fee:"",
                diners_fee:"",
                sales_processing_fee:30,
                system_usage_fee_cvv:200,
                system_usage_fee_3d_secure:200,
                system_usage_fee_confirmation_email:200,
                system_usage_fee_continuous_billing:200,
                system_usage_fee_payment_now:200,
                system_usage_fee_customer_support:200,
                system_usage_fee_early_payment_service:200,
                system_usage_fee_div2_bonus:200,
                system_usage_fee_id_password_storage:200,
                monthly_payment_method:300,
                monthly_option_fee_cvv:300,
                monthly_option_fee_3d_secure:300,
                monthly_option_fee_confirmation_email:300,
                monthly_option_fee_continuous_billing:300,
                monthly_option_fee_payment_now:300,
                monthly_option_fee_customer_support:300,
                monthly_option_fee_early_deposit_service:300,
                monthly_option_fee_div2_bonus:300,
                monthly_option_fee_id_password_storage:300,
                option_fee:30,
                zeus_incentive_id: 1
            },
            cvs_setting_masters:{
                payment_site:"",
                early_payment_flag:false,
                opening_contract_fee:3000,
                system_usage_fee_confirmation_email:200,
                system_usage_fee_customer_support:200,
                system_usage_fee_payment_now:200,
                fee_range_2000:100,
                fee_range_3000:150,
                fee_range_5000:200,
                fee_range_7000:250,
                fee_range_10000:300,
                fee_range_30000:350,
                fee_range_50000:400,
                fee_range_100000:450,
                fee_range_200000:500,
                fee_range_300000:550,
                sales_processing_fee:30,
                monthly_payment_method:300,
                monthly_option_fee_confirmation_email:300,
                monthly_option_fee_customer_support:300,
                monthly_option_fee_early_deposit_service:300,
                option_fee:30,
                zeus_incentive_id: 1
            },
            bank_setting_masters:{
                payment_site:"",
                early_payment_flag:false,
                fee:"",
                opening_contract_fee:3000,
                sales_processing_fee:30,
                system_usage_fee_confirmation_email:200,
                system_usage_fee_customer_support:200,
                system_usage_fee_early_payment_service:200,
                monthly_payment_method:300,
                monthly_option_fee_confirmation_email:300,
                monthly_option_fee_customer_support:300,
                monthly_option_fee_early_deposit_service:300,
                option_fee:30,
                zeus_incentive_id: 1
            },
            system_setting_masters: {
                system_setting_info_id: "",
                credit_secureapi_flag: true,
                credit_securelink_flag: true,
                credit_linkpoint_flag: true,
                credit_div2_bonus_agent_flag: true,
                credit_div2_bonus_merchant_flag: false,
                credit_3d_agent_flag:true,
                credit_3d_merchant_flag:false,
                credit_cvv_agent_flag:true,
                credit_cvv_merchant_flag:false,
                credit_cvv_agent_setting:"",
                credit_subscription_agent_flag:true,
                credit_subscription_merchant_flag:false,
                credit_auth_agent_flag:true,
                credit_auth_merchant_flag:false,
                credit_send_mail_agent_flag:true,
                credit_send_mail_merchant_flag:false,
                cvs_securelink_flag:true,
                cvs_linkpoint_flag:true,
            },
            shop_addition_masters: {
                review_content_info_id: "",
                case_load_1_month_ago:"",
                can_do_without_seven:false,
                payment_timing:"",
                special_for_sale:false,
                content_special_for_sale:"",
                cancel_halfway:false,
                card_number_pass_flag:false,
                pcidss_protection_flag:false,
                injustice_address_using_flag:false,
                behavior_analusis_imple_flag:0,
                other_measures_flag:false,
                other_measures_comment:"",
                door_to_door_sales_flag:false,
                telemarketing_sales_flag:false,
                multilevel_marketing_sales_flag:false,
                bor_sales_transactions_flag:false,
                specified_continuous_service_flag:false,
            },
        }
    }
}

export const category_response = {
    response_data:{
        "code": "",
        "message": "",
        "category_major": {
            0: {
                "name": "",
                "zeus_major_code": "",
                "category_medium": {
                    0: {
                        "name": "",
                        "zeus_medium_code": "",
                        "shop_addition_license_flag": false,
                        "shop_addition_antique_flag": false,
                        "highrisk_flag": false,
                        "shop_addition_prepaid_flag": false,
                        "shop_addition_subscription_flag": false,
                        "shop_addition_point_purchase_flag": false,
                        "shop_addition_reserved_product_flag": false,
                        "shop_addition_estimated_product_flag": false,
                        "shop_addition_rental_flag": false,
                        "btob_flag": false,
                        "special_rate_btob_flag": false,
                        "real_store_flag": false
                    },
                },
            },
        },
    }
}

export const initExaminationStatusContext = {
    "entry": {
        "merchant_id": "",
        "merchant_pw": "", 
        "status": "",
        "service_code_list": "",
        "service": {
            "bank": {
                "ip_code": "",
                "status": ""
            },
            "credit": {
                "ip_code": "",
                "status": "",
                "use_brand": {
                    "amex": {
                        "div_status": {
                            "bonus": false,
                            "div": false,
                            "div2": false,
                            "one": false,
                            "rivo": false
                        },
                        "status": ""
                    },
                    "diners": {
                        "div_status": {
                            "bonus": false,
                            "div": false,
                            "div2": false,
                            "one": false,
                            "rivo": false
                        },
                        "status": ""
                    },
                    "jcb": {
                        "div_status": {
                            "bonus": false,
                            "div": false,
                            "div2": false,
                            "one": false,
                            "rivo": false
                        },
                        "status": ""
                    },
                    "master": {
                        "div_status": {
                            "bonus": false,
                            "div": false,
                            "div2": false,
                            "one": false,
                            "rivo": false
                        },
                        "status": ""
                    },
                    "visa": {
                        "div_status": {
                            "bonus": false,
                            "div": false,
                            "div2": false,
                            "one": false,
                            "rivo": false
                        },
                        "status": ""
                    }
                },
                "zkey": ""
            },
            "cvs": {
                "ip_code": "",
                "status": "",
                "use_cvs": {
                    "familymart": "",
                    "lawson": "",
                    "seicomart": "",
                    "seven": "",
                    "yamazaki": ""
                },
                "zkey": ""
            },
            "atobarai": {
                "api_code": "",
                "ip_code": "",
                "status": ""
            },
        },
    }
}